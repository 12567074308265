<template>
    <div class="container-fluid">
        <!-- Filters -->
        <div class="card mt-3">
            <div class="card-body">
                <!-- Time -->
                <el-select
                    class="me-2"
                    size="small"
                    v-model="filters.timeSelected"
                    @visible-change="handleSelectedTimeChange"
                    placeholder="Time">
                    <el-option
                        v-for="timeOption in filters.timesOptions"
                        :key="timeOption.value"
                        :label="timeOption.label"
                        :value="timeOption.value">
                    </el-option>
                </el-select>
                <!-- Symbol -->
                <el-select
                    class="mx-2"
                    size="small"
                    multiple
                    collapse-tags
                    v-model="filters.symbolsSelected"
                    @visible-change="handleSelectedSymbolsChange"
                    placeholder="Symbols">
                    <el-option
                        v-for="symbol in filters.symbolsOptions"
                        :key="`symbols-filter-${symbol}`"
                        :label="symbol"
                        :value="symbol">
                    </el-option>
                </el-select>
                <!-- Name -->
                <el-select
                    class="mx-2"
                    size="small"
                    multiple
                    collapse-tags
                    v-model="filters.namesSelected"
                    @visible-change="handleSelectedNamesChange"
                    placeholder="Names">
                    <template>
                        <el-button class="w-100" size="small" v-if="filters.namesOptions.length !== filters.namesSelected.length" @click="filters.namesSelected = [...filters.namesOptions]">Select all</el-button>
                        <el-button class="w-100" size="small" v-else @click="filters.namesSelected = []">Deselect all</el-button>
                    </template>
                    <el-option
                        v-for="name in filters.namesOptions"
                        :key="`name-filter-${name}`"
                        :label="name"
                        :value="name">
                    </el-option>
                </el-select>
                <!-- Command -->
                <el-select
                    class="mx-2"
                    size="small"
                    multiple
                    collapse-tags
                    v-model="filters.commandsSelected"
                    @visible-change="handleSelectedCommandsChange"
                    placeholder="Commands">
                    <el-option
                        v-for="command in commandsOptions"
                        :key="`command-filter-${command.value}`"
                        :label="command.label"
                        :value="command.value">
                    </el-option>
                </el-select>
                <!-- Magic -->
                <el-select
                    class="mx-2"
                    size="small"
                    multiple
                    collapse-tags
                    v-model="filters.magicsSelected"
                    @visible-change="handleSelectedMagicsChange"
                    placeholder="Magics">
                    <el-option
                        v-for="magic in filters.magicsOptions"
                        :key="`magic-filter-${magic}`"
                        :label="magic"
                        :value="magic">
                    </el-option>
                </el-select>
            </div>
        </div>
        <!-- Table -->
        <div class="card my-3">
            <div class="card-body">
                <VueVirtualTable
                    ref="trade-events-table"
                    class="trade-events-table"
                    v-loading="isTableLoading"
                    :minWidth="1380"
                    :height="tradeEventsTableHeight"
                    :bordered="false"
                    :config="tradeEventsTableConfig"
                    :data="tradeEventsTableData"
                />
            </div>
        </div>
    </div>
</template>

<script>
import {DateTime} from 'luxon'
import db from '@/plugins/dexie'
import {tradeTypeToName} from '@/common/helpers'
import _ from 'lodash'
import VueVirtualTable from 'vue-virtual-table'

/*
    Log work only with local storage,
    tradeEvents update and notification displays
    implemented in main admin component

    TODO: table update
 */

export default {
    name: 'Log',
    i18n: {
        messages: {
            en: {
                openTime: 'Time',
                name: 'Name',
                command: 'Command',
                ticket: 'Ticket',
                symbol: 'Symbol',
                type: 'Type',
                lots: 'Lots',
                profit: 'Profit',
                magic: 'Magic',
                comment: 'Comment',
                buyLots: 'Buy Lots',
                sellLots: 'Sell Lots',
                price: 'Price',
                drawdown: 'Drawdown',
            },
            ru: {
                openTime: 'Время',
                name: 'Имя',
                command: 'Команда',
                ticket: 'Тикет',
                symbol: 'Пара',
                type: 'Тип',
                lots: 'Лоты',
                profit: 'Прибыль',
                magic: 'Мэджик',
                comment: 'Коммент',
                buyLots: 'Buy лотов',
                sellLots: 'Sell лотов',
                price: 'Цена',
                drawdown: 'Просадка',
            }
        }
    },
    components: {
        VueVirtualTable,
    },
    data: function () {
        return {
            tradeEventsTableConfig: [
                {
                    prop: 'open_time',
                    name: this.$t('openTime'),
                    width: 180,
                },
                {
                    prop: 'name',
                    name: this.$t('name'),
                    width: 70,
                },
                {
                    prop: 'command',
                    name: this.$t('command'),
                    width: 100,
                },
                {
                    prop: 'ticket',
                    name: this.$t('ticket'),
                    width: 120,
                },
                {
                    prop: 'symbol',
                    name: this.$t('symbol'),
                    width: 80,
                },
                {
                    prop: 'type',
                    name: this.$t('type'),
                    width: 90,
                },
                {
                    prop: 'lots',
                    name: this.$t('lots'),
                    width: 70,
                },
                {
                    prop: 'profit',
                    name: this.$t('profit'),
                    width: 70,
                },
                {
                    prop: 'magic',
                    name: this.$t('magic'),
                    width: 70,
                },
                {
                    prop: 'comment',
                    name: this.$t('comment'),
                    width: 180,
                },
                {
                    prop: 'buy_lots',
                    name: this.$t('buyLots'),
                    width: 80,
                },
                {
                    prop: 'sell_lots',
                    name: this.$t('sellLots'),
                    width: 80,
                },
                {
                    prop: 'price',
                    name: this.$t('price'),
                    width: 90,
                },
                {
                    prop: 'drawdown',
                    name: this.$t('drawdown'),
                    width: 100,
                },
            ],
            tradeEventsTableData: [],
            tradeEventsTableHeight: 500,
            isTableLoading: false,
            // Time, Name, Command, Symbol, Magic
            filters: {
                // in seconds
                timesOptions: [
                    {
                        value: 60 * 60,
                        label: '1 hour'
                    },
                    {
                        value: 60 * 60 * 4,
                        label: '4 hour'
                    },
                    {
                        value: 60 * 60 * 24,
                        label: 'Day'
                    },
                    {
                        value: 60 * 60 * 168,
                        label: 'Week'
                    },
                    {
                        value: 60* 60 * 672,
                        label: 'Month'
                    }
                ],
                timeSelected: null,

                namesOptions: [],
                namesSelected: [],

                symbolsOptions: [],
                symbolsSelected: [],

                commandsSelected: [],

                magicsOptions: [],
                magicsSelected: [],
            },
        }
    },
    computed: {
        selectedAccount: function () {
            return this.$store.getters.ADMIN_SELECTED_ACCOUNT
        },
        commandsOptions: function () {
            return this.$store.getters['admin/logSettings/commandsOptions']
        }
    },
    methods: {
        timeFormatter: function (cellValue) {
            return DateTime.fromISO(cellValue).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)
        },
        commandFormatter: function(cellValue) {
            return cellValue < 6 ? 'Open' : 'Close'
        },
        typeFormatter: function (cellValue) {
            return tradeTypeToName(cellValue)
        },
        prepareFilters: async function () {
            // Set symbols options
            this.filters.symbolsOptions = await db.keeper_log.orderBy('symbol').uniqueKeys()

            // Set names options
            this.filters.namesOptions = await db.keeper_log.orderBy('name').uniqueKeys()

            // Set magics options
            this.filters.magicsOptions = await db.keeper_log.orderBy('magic').uniqueKeys()

            // Set selected time
            try {
                const selectedTime = JSON.parse(localStorage.getItem('admin-log-selected-time'))
                this.filters.timeSelected = selectedTime ?? this.filters.timesOptions[0].value
            } catch (e) {
                console.warn('Can\'t parse selected time')
                this.filters.timeSelected = this.filters.timesOptions[0].value
            }

            // Set selected symbols
            try {
                const selectedSymbols = JSON.parse(localStorage.getItem('admin-log-selected-symbols'))
                this.filters.symbolsSelected = selectedSymbols ?? this.filters.symbolsOptions
                if(this.filters.symbolsSelected.length === 0) {
                    this.filters.symbolsSelected = this.filters.symbolsOptions
                }
            } catch (e) {
                console.warn('Can\'t parse selected symbols')
                this.filters.symbolsSelected = this.filters.symbolsOptions
            }

            // Set selected names
            try {
                if(this.selectedAccount && this.filters.namesOptions.includes(this.selectedAccount.name)) {
                    this.filters.namesSelected = [this.selectedAccount.name]
                } else {
                    this.filters.namesSelected = this.filters.namesOptions
                }
            } catch (e) {
                console.warn('Can\'t parse selected names')
                this.filters.namesSelected = this.filters.namesOptions
            }

            // Set selected magics
            try {
                const selectedMagics = JSON.parse(localStorage.getItem('admin-log-selected-magics'))
                this.filters.magicsSelected = selectedMagics ?? this.filters.magicsOptions
                if(this.filters.magicsSelected.length === 0) {
                    this.filters.magicsSelected = this.filters.magicsOptions
                }
            } catch (e) {
                console.warn('Can\'t parse selected magics')
                this.filters.magicsSelected = this.filters.magicsOptions
            }

            // Set selected commands
            try {
                const selectedCommands = JSON.parse(localStorage.getItem('admin-log-selected-commands'))
                this.filters.commandsSelected = selectedCommands ?? this.commandsOptions.map(option => option.value)
            } catch (e) {
                console.warn('Can\'t parse selected commands')
                this.filters.commandsSelected = this.commandsOptions.map(option => option.value)
            }
        },
        handleSelectedTimeChange: function (visible) {
            if(visible) {
                return
            }
            localStorage.setItem('admin-log-selected-time', JSON.stringify(this.filters.timeSelected))
            this.applyFilters()
        },
        handleSelectedSymbolsChange: function (visible) {
            if(visible) {
                return
            }
            localStorage.setItem('admin-log-selected-symbols', JSON.stringify(this.filters.symbolsSelected))
            this.applyFilters()
        },
        handleSelectedMagicsChange: function (visible) {
            if(visible) {
                return
            }
            localStorage.setItem('admin-log-selected-magics', JSON.stringify(this.filters.magicsSelected))
            this.applyFilters()
        },
        handleSelectedNamesChange: function (visible) {
            if(visible) {
                return
            }
            localStorage.setItem('admin-log-selected-names', JSON.stringify(this.filters.namesSelected))
            this.applyFilters()
        },
        handleSelectedCommandsChange: function (visible) {
            if(visible) {
                return
            }
            localStorage.setItem('admin-log-selected-commands', JSON.stringify(this.filters.commandsSelected))
            this.applyFilters()
        },
        applyFilters: function () {
            this.isTableLoading = true
            this.tradeEventsTableData = []
            db.keeper_log
                .where('open_time')
                .above(new DateTime('utc').minus({seconds: this.filters.timeSelected}).toUTC().toJSON())
                .and(row => this.filters.symbolsSelected.includes(row.symbol))
                .and(row => this.filters.magicsSelected.includes(row.magic))
                .and(row => this.filters.namesSelected.includes(row.name))
                .and(row => {
                    // Detect row type
                    let rowType = null
                    if(row.command !== 6 && row.type >= 2) {
                        rowType = 'open_pending'
                    }
                    if(row.command === 6 && row.type >= 2) {
                        rowType = 'close_pending'
                    }
                    if(row.command !== 6 && row.type <= 1) {
                        rowType = 'open_market'
                    }
                    if(row.command === 6 && row.type <= 1) {
                        rowType = 'close_market'
                    }

                    return this.filters.commandsSelected.includes(rowType)
                })
                .toArray()
                .then(rows => {
                    let sorted = rows.sort((a,b) => b.id - a.id)
                    for(let i = 0; i < sorted.length; i++) {
                        sorted[i]['open_time'] = this.timeFormatter(sorted[i]['open_time'])
                        sorted[i]['command'] = this.commandFormatter(sorted[i]['command'])
                        sorted[i]['type'] = this.typeFormatter(sorted[i]['type'])

                        if(typeof sorted[i]['price'] !== 'number') {
                            continue;
                        }

                        if(sorted[i]['symbol'].includes('JPY')) {
                            sorted[i]['price'] = sorted[i]['price'].toFixed(3)
                        } else {
                            sorted[i]['price'] = sorted[i]['price'].toFixed(5)
                        }
                    }

                    this.tradeEventsTableData = sorted
                })
                .finally(() => {
                    this.isTableLoading = false
                })
        },
        listenDBUpdate: function (primKey, origRow) {
            let row = Object.assign({}, origRow)

            // Check if we have new values for filters
            if(!this.filters.symbolsOptions.includes(row.symbol)) {
                this.filters.symbolsOptions.push(row.symbol)
                this.filters.symbolsSelected.push(row.symbol)
            }
            if(!this.filters.magicsOptions.includes(row.magic)) {
                this.filters.magicsOptions.push(row.magic)
                this.filters.magicsSelected.push(row.magic)
            }
            if(!this.filters.namesOptions.includes(row.name)) {
                this.filters.namesOptions.push(row.name)
                this.filters.namesSelected.push(row.name)
            }

            if (this.filters.symbolsSelected.includes(row.symbol) &&
                this.filters.magicsSelected.includes(row.magic) &&
                this.filters.namesSelected.includes(row.name)
            ) {
                // Detect row type
                let rowType = null
                if(row.command !== 6 && row.type >= 2) {
                    rowType = 'open_pending'
                }
                if(row.command === 6 && row.type >= 2) {
                    rowType = 'close_pending'
                }
                if(row.command !== 6 && row.type <= 1) {
                    rowType = 'open_market'
                }
                if(row.command === 6 && row.type <= 1) {
                    rowType = 'close_market'
                }

                if(this.filters.commandsSelected.includes(rowType)) {
                    row['open_time'] = this.timeFormatter(row['open_time'])
                    row['command'] = this.commandFormatter(row['command'])
                    row['type'] = this.typeFormatter(row['type'])

                    if(typeof row['price'] === 'number') {
                        if(row['symbol'].includes('JPY')) {
                            row['price'] = row['price'].toFixed(3)
                        } else {
                            row['price'] = row['price'].toFixed(5)
                        }
                    }

                    this.tradeEventsTableData.unshift(row)
                }
            }
        },
        /*
        rowClassName: function ({row}) {
            if(row.type <= 1 && row.command !== 6) {
                return 'open-market-row'
            }

            if(row.command === 6) {
                return 'close-row'
            }
        },
         */
        windowResizeHandler: _.debounce(function () {
            let rect = this.$refs['trade-events-table'].$el.getBoundingClientRect()
            this.tradeEventsTableHeight = window.innerHeight - rect.y - 30
        }, 200)
    },
    mounted: async function () {
        await this.prepareFilters()
        this.applyFilters()
        db.keeper_log.hook('creating', this.listenDBUpdate)

        this.windowResizeHandler()
        window.addEventListener('resize', this.windowResizeHandler)
    },
    beforeDestroy() {
        db.keeper_log.hook('creating').unsubscribe(this.listenDBUpdate)

        window.removeEventListener('resize', this.windowResizeHandler)
    }
}
</script>

<style scoped>
    .trade-events-table >>> .openMarketRow {
        background-color: rgb(224, 221, 221);
    }
    .trade-events-table >>> .closeRow {
        background-color: rgb(177, 176, 176);
    }

    .trade-events-table >>> * {
        font-size: 14px;
    }
    .trade-events-table >>> .header-cell-inner {
        color: #000;
        font-weight: 600;
    }

    .trade-events-table {
        border: none !important;
    }
</style>
